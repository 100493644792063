.dbi-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 12px !important;
  padding: 11px 15.5px !important;
  border-radius: 10px;
  font-weight: 700;
  height: 40px !important;
  border: 2px solid #3d72a3 !important;
  box-shadow: 0px 2px 4px rgba(61, 114, 163, 0.06), 0px 4px 6px rgba(61, 114, 163, 0.1);
  white-space: nowrap;

  .button-loader {
    margin-right: 5px;

    .loader {
      width: 24px;
      height: 24px;
      border-top: 2px solid rgba(61, 114, 164, 0.25);
      border-right: 2px solid rgba(61, 114, 164, 0.25);
      border-bottom: 2px solid rgba(61, 114, 164, 0.25);
      border-left: 2px solid #3D72A3;

      &::after {
        width: 24px;
        height: 24px;
      }
    }
  }

  &:focus {
    background-color: initial !important;
    box-shadow: initial !important;
  }

  &:hover {
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: 0px 20px 25px rgba(61, 114, 163, 0.1), 0px 10px 10px rgba(61, 114, 163, 0.04) !important;
    background-color: initial !important;
  }

  &.dbi-prefix {
    svg {
      margin-right: 9px;
    }
  }

  &.dbi-suffix {
    svg {
      margin-left: 9px;
    }
  }

  &.dbi-disabled,
  &:disabled {
    cursor: not-allowed;
    box-shadow: none !important;
    background-color: #b1c7da;
    transition: background-color 0.1s ease-in-out;
  }

  &.size__20 {
    font-size: 12px !important;
    height: 20px !important;
  }

  &.size__40 {
    font-size: 12px !important;
    line-height: 18px !important;
    height: 40px !important;
  }

  &.size__55 {
    font-size: 18px;
    line-height: 27px;
    height: 55px !important;
  }

  &.color__outline-secondary {
    color: #3d72a3;

    &.dbi-disabled {
      background: transparent !important;
    }
  }

  &.color__secondary {
    color: #ffffff;
    transition: 0.1s ease-in-out;

    &:focus {
      background-color: #3d72a3 !important;
    }

    &:hover {
      background-color: #3d72a3 !important;
    }

    .loading-wrapper {
      .loader {
        border-top: 2px solid #FFFF;
        border-right: 2px solid #FFFF;
        border-bottom: 2px solid #FFFF;
        border-left: 2px solid rgba(255, 255, 255, 0.20);
      }
    }
  }

  &.color__transparent {
    color: #648EB5;
    box-shadow: none !important;
    font-weight: 700 !important;
    // font-size: 16px !important;
    line-height: 12px !important;
    background-color: transparent;
    border: 0 !important;
    padding: 0 !important;

    &:hover {
      transition: color 0.1s ease-in-out;
      color: #3D72A3;
    }
  }

  &.color__simple {
    border: 0 !important;
    padding: 11px 0 !important;
    color: #3d72a3 !important;
  }

  &.color__danger {
    border-color: #FF6262 !important;
    background-color: #FF6262 !important;
  }
}
