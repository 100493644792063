.form-item-wrapper {
  display: flex;
  flex-direction: column;

  span:first-child {
    margin: 0 0 7px 0 !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #464646;
  }
}
