// Base Extended (General styles which are not added 'base styles')
@import 'base-extended';

@import 'dark-layout';

@import 'rtl';

@import 'reactstrap/index';

@import 'app-loader';

.general-border-radius {
  .table-responsive {
    border-radius: 10px;
  }

  border-radius: 10px;

  @media (max-width: 599px) {
    .table-responsive {
      border-radius: 0;
    }
  }
}

.text-heading {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.text-body {
  font-size: 14px;
  line-height: 20px;
}

.text-dark {
  color: #27272e !important;
}

.text-light {
  color: #425466 !important;
}
