.module__progress-wrapper {
  padding: 23px 20px 20px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #3D72A3;
  }

  .progress-data {
    display: flex;
    flex-direction: column;

    .value {
      align-self: flex-end;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #181818;

    }

    .progress {
      margin-top: 3px;
      height: 4px !important;
    }
  }
}
