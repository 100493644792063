.loading-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;
    top: 40px;
    margin-top: 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24.48px;
    text-align: center;
    color: #3D72A3;
  }

  .loader {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border-top: 5px solid rgba(61, 114, 164, 0.25);
    border-right: 5px solid rgba(61, 114, 164, 0.25);
    border-bottom: 5px solid rgba(61, 114, 164, 0.25);
    border-left: 5px solid #3D72A3;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;

    &:after {
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }

    &.white__loader {
      border-top: 5px solid rgba(181, 181, 181, 0.25) !important;
      border-right: 5px solid rgba(181, 181, 181, 0.25) !important;
      border-bottom: 5px solid rgba(181, 181, 181, 0.25) !important;
      border-left: 5px solid rgb(255, 255, 255) !important;
    }
  }



  @-webkit-keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
