//  ===============================================================================================
//  File Name: mixins.scss
//  Description: Extended mixins file with new mixins features.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
//  ================================================================================================

// Components
@import 'mixins/_navs';

// custom mixins 

@mixin skeleton {
  @keyframes skeleton-pulse {
    to {
      background-position: -135% 0%;
    }
  }

  background-image: linear-gradient(-90deg, #efefef 0%, #fcfcfc 20%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  background-position: 0% 0%;
  animation: skeleton-pulse 1s ease-in-out infinite;
  border-radius: rem(8px);
}

@mixin skeleton-text {
  @include skeleton;

  color: transparent !important;

  > * {
    opacity: 0;
  }
}

.skeleton,
.skeleton-auto {
  height: 100%;
  width: 100%;
  @include skeleton;
}

.skeleton-auto {
  height: auto !important;
}

.skeleton-text {
  @include skeleton-text;
}
