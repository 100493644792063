.input-wrapper {
  display: flex;
  position: relative;
  max-width: 100%;
  width: 100%;
  justify-content: center;

  input {
    -webkit-appearance: none;
    max-width: 202px;
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    padding-left: 44px;
    height: 40px;
    border: 1px solid #3d72a3;
    color: #3d72a3 !important;
    border-radius: 10px !important;
    align-self: center;
    background-color: transparent;

    &::placeholder {
      color: #3d72a3;
      font-weight: 500;
      font-size: 13px;
      // line-height: 13px;
      overflow: visible;
    }

    &:focus,
    &.check-input {
      border: 1px solid #3d72a3;
      background-color: transparent;
    }
  }

  textarea {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #a3a3a3;
    border: 1px solid #b1c7da;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(61, 114, 163, 0.06), 0px 4px 6px rgba(61, 114, 163, 0.1);

    &:focus,
    &.check-input {
      border: 1px solid #3d72a3;
      background-color: #ffffff;
    }
  }

  .prefix {
    position: relative;
    z-index: 1;
    left: 33px;
    top: 9px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .error {
    padding-top: 5px;
    font-size: 12px;
    line-height: 18px;
    color: #ff6262;
  }
}
