.loading-screen__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #FFFF;
  height: 100%;

  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    padding: 0 10px;
  }

  .loading-wrapper {
    margin-top: 40px;
  }
}
